// Skapa, steg1 och steg2, och hantera Skadeinformation
import { FormGroup, Label, Input, Col, Row } from "reactstrap";
import Switch from "components/InputSwitch";
import HelpDialog from "components/HelpDialog";
import SelectVehicle from "../SelectVehicle";
import FileUpload from "../FileUpload";
import CounterPartFormList from "../CounterPartFormList";
import { useFormikContext } from "formik";
import { useEffect } from "react";

export const StepTwoFormItems = ({ insuranceCategory, vehicles, vehiclesStatus, values, errors, touched, handleBlur, handleChange, setFieldValue }) => {
	const { submitCount, isValid } = useFormikContext();

	useEffect(() => {

		if (!isValid)
		{
			const errorElement = document.querySelector('#topOfForm');

			if (errorElement) {
				//console.log("scrolling to element", errorElement);
				errorElement.scrollIntoView({ behavior: "smooth" });
			}
		}
	},[submitCount, isValid])
	
	return (
		<>
			<Row>
				<Col sm={8}>
					<FormGroup>
						<Label>Fordon</Label>
							<SelectVehicle
								vehiclesStatus={vehiclesStatus}
								vehicles={vehicles}
								vehicleId={values.vehicleId}
								className={errors.vehicleId ? "form-control is-invalid" : "form-control"}
								setVehicleId={(vehicleId, vehicleFullName) => {
									setFieldValue("vehicleId", vehicleId);
									setFieldValue("vehicleFullName", vehicleFullName);
								}}
							/>
						{errors.vehicleId ? <div className="invalid-feedback d-block">{errors.vehicleId}</div> : null}
					</FormGroup>
				</Col>
				<Col sm={4}>
					<FormGroup>
						<Label>Mätarställning</Label>
						<Input
							className={errors.mileage ? "is-invalid" : ""}
							autoComplete="off"
							name="mileage"
							value={values.mileage}
							onChange={handleChange}
							onBlur={handleBlur}
						></Input>
						{errors.mileage ? <div className="invalid-feedback d-block">{errors.mileage}</div> : null}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col sm={8}>
					<FormGroup>
						<Label>Plats</Label>
						<Input
							className={errors.location ? "is-invalid" : ""}
							autoComplete="off"
							name="location"
							value={values.location}
							onChange={handleChange}
							onBlur={handleBlur}
						></Input>
						{errors.location ? <div className="invalid-feedback d-block">{errors.location}</div> : null}
					</FormGroup>
				</Col>
				<Col sm={4}>
					<FormGroup>
						<Label>Antal passagerare</Label>
						<Input
							className={errors.numberOfPassengers ? "is-invalid" : ""}
							autoComplete="off"
							name="numberOfPassengers"
							value={values.numberOfPassengers}
							onChange={handleChange}
							onBlur={handleBlur}
						></Input>
						{errors.numberOfPassengers ? <div className="invalid-feedback d-block">{errors.numberOfPassengers}</div> : null}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<Label>Händelsebeskrivning</Label>
						<HelpDialog
							title="Vittnen"
							message={<>
								<p>Beskriv händelsen utifrån vad som hänt och vad som förorsakat händelsen</p>
								<p>tex fordon A kommer på huvudled eller fordon B kommer från vänster!</p>
								<p>Tänk på att försäkringsbolaget måste kunna förstå vad som hänt. Lägg därför vikt vid t.ex. hur har skadan inträffat, vad är skadat, vad skadar fordonet, är där andra fordon inblandade?, vad gör respektive fordon innan kollisionen inträffar, åt vilket håll kör ni och motparten?</p>
							</>}
						/>
						<Input
							className={errors.descriptionOfEvents ? "is-invalid" : ""}
							autoComplete="off"
							name="descriptionOfEvents"
							value={values.descriptionOfEvents}
							type="textarea"
							style={{height: "200px"} }							
							onChange={handleChange}
							onBlur={handleBlur}
						></Input>
						{errors.descriptionOfEvents ? <div className="invalid-feedback d-block">{errors.descriptionOfEvents}</div> : null}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12} lg={4}>
					<FormGroup className="mt-2">
						<Switch
							onChange={() => setFieldValue("replacementVehicleHired", !values.replacementVehicleHired)}
							checked={values.replacementVehicleHired}
							label="Anlitades ersättningsfordon?"
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12} lg={4}>
					<FormGroup className="mt-2">
						<Switch
							onChange={() => setFieldValue("visibleDamages", !values.visibleDamages)}
							checked={values.visibleDamages}
							label="Synliga skador på fordon eller andra föremål?"
						/>
					</FormGroup>
				</Col>
			</Row>
			{values.visibleDamages && (
				<Row>
					<Col xs={12}>
						<FormGroup>
							<Label>Beskriv synliga skador</Label>
							<Input
								className={errors.visibleDamagesDescription ? "is-invalid" : ""}
								autoComplete="off"
								name="visibleDamagesDescription"
								value={values.visibleDamagesDescription}
								type="textarea"
								onChange={handleChange}
								onBlur={handleBlur}
							></Input>
						</FormGroup>
					</Col>
				</Row>
			)}
			<Row>
				<Col xs={12} lg={4}>
					<FormGroup className="mt-2">
						<Switch onChange={() => setFieldValue("personalDamages", !values.personalDamages)} checked={values.personalDamages} label="Personskada?" />
					</FormGroup>
				</Col>
			</Row>
			{values.personalDamages && (
				<Row>
					<Col xs={12}>
						<FormGroup>
							<Label>Beskriv personskador: Personnummer, Namn, Adress, Telefon (om möjligt)</Label>
							<Input
								className={errors.personalDamagesDescription ? "is-invalid" : ""}
								autoComplete="off"
								name="personalDamagesDescription"
								value={values.personalDamagesDescription}
								type="textarea"
								onChange={handleChange}
								onBlur={handleBlur}
							></Input>
						</FormGroup>
					</Col>
				</Row>
			)}
			<Row className="mb-3">
				<Col xs={12} lg={4}>
					<FormGroup>
						<Label>Foton</Label>
						<FileUpload
							attachments={values.images}
							onSuccessUpload={(uploadedImage) => {
								const newArray = [...values.images, uploadedImage];

								setFieldValue("images", newArray);
							}}
							onRemoveImageItem={(name) => {
								const newArray = values.images.filter((item) => item.name !== name);
								setFieldValue("images", newArray);
							}}
						/>
					</FormGroup>
				</Col>
			</Row>

			{(insuranceCategory === "CRASH" || insuranceCategory === "THEFT") && (
				<Row>
					<Col xs={12}>
						<CounterPartFormList
							items={values.counterPartContactInformations}
							onAdd={(item) => {
								const newArray = [...values.counterPartContactInformations, item];
								setFieldValue("counterPartContactInformations", newArray);
							}}
							onRemove={(name) => {
								const newArray = values.counterPartContactInformations.filter((item) => item.name !== name);
								setFieldValue("counterPartContactInformations", newArray);
							}}
						/>
					</Col>
				</Row>
			)}
			<Row>
				<Col xs={12}>
					<FormGroup>
						<Label>Vittnen</Label>
						<HelpDialog
							title="Vittnen"
							message={
								<>
									<p>Om vittnen anges skall dessa om möjligt innehålla följande information:</p>
									<ul>
										<li>Namn</li>
										<li>E-post</li>
										<li>Adress</li>
										<li>Postnr och postadress</li>
										<li>Telefon kontorstid</li>
									</ul>
								</>
							}
						/>
						<Input
							className={errors.witnesses ? "is-invalid" : ""}
							autoComplete="off"
							name="witnesses"
							value={values.witnesses}
							type="textarea"
							onChange={handleChange}
							onBlur={handleBlur}
						></Input>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<Label>Övrigt</Label>
				</Col>
				<Col xs={12}>
					<FormGroup className="mt-2">
						<Switch onChange={() => setFieldValue("policeContacted", !values.policeContacted)} checked={values.policeContacted} label="Polis inkopplad?" />
					</FormGroup>
					<FormGroup className="mt-2">
						<Switch
							onChange={() => setFieldValue("emergencyServiceContacted", !values.emergencyServiceContacted)}
							checked={values.emergencyServiceContacted}
							label="Räddningstjänst inkopplad?"
						/>
					</FormGroup>
					<FormGroup className="mt-2">
						<Switch onChange={() => setFieldValue("bloodTest", !values.bloodTest)} checked={values.bloodTest} label="Har blodprov tagits?" />
					</FormGroup>
					<FormGroup className="mt-2">
						<Switch onChange={() => setFieldValue("breathTest", !values.breathTest)} checked={values.breathTest} label="Har utandningsprov tagits?" />
					</FormGroup>
					<FormGroup className="mt-2">
						<Switch onChange={() => setFieldValue("isVehicleTowed", !values.isVehicleTowed)} checked={values.isVehicleTowed} label="Är fordonet bärgat?" />
					</FormGroup>
				</Col>
			</Row>
			{values.isVehicleTowed && (
				<Row>
					<Col sm={6}>
						<FormGroup>
							<Label>Var bärgades fordonet?</Label>
							<Input autoComplete="off" name="vehicleTowedToLocation" value={values.vehicleTowedToLocation} onChange={handleChange} onBlur={handleBlur}></Input>
						</FormGroup>
					</Col>
				</Row>
			)}
		</>
	);
};
